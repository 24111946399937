import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { TicketModule } from '../ticket/ticket.module';
import { FeedbackBubbleComponent } from './feedback-bubble/feedback-bubble.component';
import { FeedbackRoutingModule } from './feedback-routing.module';
import { FeedbackRowComponent } from './feedback/feedback-row/feedback-row.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackDeleteModalComponent } from './modals/feedback-delete-modal/feedback-delete-modal.component';
import { FeedbackModalComponent } from './modals/feedback-modal/feedback-modal.component';

@NgModule({
	imports: [CommonModule, FeedbackRoutingModule, SharedModule, TicketModule],
	declarations: [FeedbackBubbleComponent, FeedbackModalComponent, FeedbackComponent, FeedbackRowComponent, FeedbackDeleteModalComponent],
	exports: [FeedbackBubbleComponent],
})
export class FeedbackModule {}
