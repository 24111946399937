import { Component, Input } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { User } from 'src/app/shared/models';
import { FeedbackModalComponent } from '../modals/feedback-modal/feedback-modal.component';

@Component({
	selector: 'pk-broker-feedback-bubble',
	templateUrl: './feedback-bubble.component.html',
	styleUrls: ['./feedback-bubble.component.scss'],
})
export class FeedbackBubbleComponent {
	@Input() page: string;
	@Input() user: User;

	public isMinimized = false;

	constructor(private modalService: BsModalService) {}

	public openFeedback(): void {
		const modalRef = this.modalService.show(FeedbackModalComponent, {
			class: 'feedback-modal modal-xl modal-dialog-centered modal-dialog-centered-custom',
		});
		modalRef.content.init(this.page);
	}

	public minimizeFeedback(): void {
		this.isMinimized = true;
	}
}
