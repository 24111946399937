<pk-broker-modal>
	<ng-template #headerTemplate>
		<h5 class="modal-title openAnim1" *ngIf="!showSuccess" id="initialHeader">We want to hear from you!</h5>
		<h5 class="modal-title" *ngIf="showSuccess" id="confirmationHeader">Thank you for your feedback!</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<form [formGroup]="formGroup" *ngIf="!loading">
			<div class="container-fluid">
				<div class="row d-flex justify-content-center" *ngIf="!showSuccess">
					<!-- Left side with question 1 -->
					<div class="col-12 col-md-6 mb-3 mb-md-0 feedback-left" id="feedbackLeft">
						<div class="row">
							<div class="col-12">
								<p class="label-style text-left text-md-center openAnim2">What kind of feedback would you like to leave?</p>
							</div>
						</div>
						<div class="row feedback-question-01">
							<div
								*ngFor="let feedbackType of feedbackTypes; let i = index"
								class="col-4 form-check openAnim3"
								[ngClass]="{
									'text-right': i === 0,
									'text-left': i === feedbackTypes.length - 1,
									'text-center': i !== 0 && i !== feedbackTypes.length - 1,
								}">
								<input
									class="form-check-input"
									type="radio"
									id="feedback{{ feedbackType.name }}"
									[value]="feedbackType.id"
									formControlName="feedbackTypeId" />
								<label class="form-check-label" id="feedback{{ feedbackType.id }}" for="feedback{{ feedbackType.name }}">
									<fa-icon [icon]="['far', CONSTANTS.feedbackText[feedbackType.id].icon]"></fa-icon><br />
									<span [innerHtml]="CONSTANTS.feedbackText[feedbackType.id].text"></span>
								</label>
							</div>
						</div>
					</div>
					<!-- Right side with the rest of the form -->
					<div
						class="col-12 col-md-6 feedback-right"
						id="feedbackRight"
						[ngClass]="{ 'd-none': !form.feedbackTypeId.value, openAnim1: form.feedbackTypeId.value }">
						<div class="form-row">
							<div class="col-12 form-group">
								<label [ngClass]="{ 'd-none': form.feedbackTypeId.value !== 1 }" id="feedbackQuestion2a" for="feedbackQuestion2">
									We'd love to hear your compliment
								</label>
								<label [ngClass]="{ 'd-none': form.feedbackTypeId.value !== 2 }" id="feedbackQuestion2b" for="feedbackQuestion2">
									Please share your concern
								</label>
								<label [ngClass]="{ 'd-none': form.feedbackTypeId.value !== 3 }" id="feedbackQuestion2c" for="feedbackQuestion2">
									What is your suggestion?
								</label>
								<textarea class="form-control" id="feedbackQuestion2" rows="4" formControlName="body"></textarea>
							</div>
						</div>
						<div class="form-row">
							<div class="col-12">
								<p class="label-style">May we contact you if we have more questions?</p>
							</div>
						</div>
						<div class="alert alert-warning" *ngIf="err.canContact">
							Please choose either Yes or No for allowing us to contact you for more questions.
						</div>
						<div class="alert alert-warning" *ngIf="err.body">Please enter some feedback to submit the form.</div>
						<div class="form-row">
							<div class="col-6 col-sm-3 mb-3 mb-sm-0 form-check pr-1">
								<input class="form-check-input" type="radio" id="feedbackYes" [value]="true" formControlName="canContact" />
								<label class="form-check-label" for="feedbackYes">Yes</label>
							</div>
							<div class="col-6 col-sm-3 mb-3 mb-sm-0 form-check pl-1">
								<input class="form-check-input" type="radio" id="feedbackNo" [value]="false" formControlName="canContact" />
								<label class="form-check-label" for="feedbackNo">No</label>
							</div>
							<div class="col-12 col-sm-6">
								<button type="button" class="btn btn-primary btn-block" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
							</div>
						</div>
					</div>
				</div>
				<!-- Confirmation message -->
				<div class="col-12" id="confirmationMsg" *ngIf="showSuccess">
					<p class="label-style text-left text-md-center">
						We take every opinion seriously and will be reaching out if you wanted us to. Thank you again!
					</p>
					<p class="label-style text-left text-md-center">You can close this window by clicking the button below.</p>
					<p class="text-center"><button type="button" class="btn btn-light" aria-label="Close" (click)="activeModal.hide()">Close</button></p>
				</div>
			</div>
		</form>
	</ng-template>
</pk-broker-modal>
